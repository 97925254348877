<template>
  <div>
    <!-- 轮播图 -->
    <div class="lbimage">
      <el-carousel height="546px" style="width: 100%">
        <el-carousel-item v-for="(item, index) in bannerImgs" :key="index">
          <img style="height: 546px" :src="item" alt=""/>
        </el-carousel-item>
      </el-carousel>
      <!-- 轮播定位内容 -->
      <div class="lbcontent">
        <div class="lb_content">
          <p class="ql-editor" style="font-size: 20px; font-weight: 700; color: white" v-html="topLabel"></p>
        </div>
        <div class="lbbutton"></div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="middlemenu">
      <!-- 业务核心 -->
      <div class="ywcenter">
        <div style="">
          <img
              class="imageww"
              src="../../static/切图文件/ICON-left.png"
              alt=""
          />
          <span class="bttitle" style="display: inlne-block">业务核心</span>
          <img
              class="imageww"
              src="../../static/切图文件/ICON-right.png"
              alt=""
          />
        </div>

        <!-- 内容导航数据 -->
        <div class="ywmenu">
          <p style="text-align: center; margin-top: 10px">{{ middlemenu }}</p>
        </div>

        <!-- 内容下面的小框  v-show="ywone" -->
        <div class="ywcontent" style="min-height: 400px">
          <div
              class="ywx_content animate__animated animate__fadeInUp"
              v-for="(item, index) in syywlist"
              :key="index"
              ref="animated1"
              @click="yewu"
              id="yehx"
          >
            <p style="margin-top: 42px; font-weight: 900; font-size: 22px">
              {{ item.title }}
            </p>
            <p style="font-weight: 900; font-size: 20px">
              {{ item.subtitle }}
            </p>
            <p
                class="ql-editor hthtml"
                style="
                  margin-top: 15px;
                  font-size: 16px;
                  color: #bbbbbb;
                "
                v-html="item.content"
            ></p>
          </div>
        </div>
      </div>
      <!-- 新闻资讯 -->
      <div class="news" style="margin-top: 40px">
        <img
            class="imageww"
            src="../../static/切图文件/ICON-left.png"
            alt=""
        />
        <span class="bttitle">新闻资讯</span>
        <img
            class="imageww"
            src="../../static/切图文件/ICON-right.png"
            alt=""
        />
        <!-- 新闻资讯详情 -->
        <div ref="animated2" class="newscontent" style="min-height: 500px">
          <div
              id="xiashang"
              v-for="(item, index) in synewslist"
              :key="index"
              @click="news(item.id)"
              class="animate__animated animate__flipInX"
              v-show="synewslist.length>0"
              style="cursor: pointer"
          >
            <div class="mdimgcontent">
              <img style="width: 358px;height:178px" :src="item.bannerImg" alt=""/>
              <p class="dw">
                  <span class="ywx_content_line1" style="padding-left: 12px">{{
                      item.title
                    }}</span>
              </p>
            </div>

            <p
                style="
                  margin-top: 12px;
                  padding-left: 12px;
                  color: #000000;
                  font-size: 12px;
                  text-align: left;
                "
                class="ywx_content_line"
            >
              {{ item.subtitle }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 更多资讯 -->
    <div class="morenews" ref="animated3">
      <el-button round class="dwbtn" @click="moremes">{{ moreNewsLabel }}</el-button>
      <div class="xtb">
        <p>战略合作</p>
      </div>

      <div v-show="hezuolist.length>0" class="hezuo animate__animated animate__zoomIn">
        <el-row :gutter="10"
            v-show="hezuolist.length>0"
            class="animate__animated animate__zoomIn"
        >
          <el-col
              v-for="(item, index) in hezuolist"
              :key="index"
              :span="4"
              :gutter="1"
              class="logo"
          ><img :src="item.bannerImg" alt="" style="cursor:pointer" @click="jump(item.link)"
          /></el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMenuList,
  getContentList
} from "../request/index";

export default {
  data() {
    return {
      ywid: "", // 业务核心
      syywlist: [], //首页业务核心数组
      synewslist: [], //新闻资讯数组
      hezuothree: false,
      middlemenu: "",
      hezuolist: [],
      menu: {},
      bannerImgs: [],
      newsPageNum: 1,
      moreNewsLabel: '更多资讯',
      topLabel: ''
    };
  },
  created() {
    getMenuList({parentId:0}).then(res => {
      this.menu = res.data[0];
      this.bannerImgs = this.menu.bannerImg.split("|");
      getMenuList({parentId: this.menu.id}).then(res => {
        let arr = res.data;
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].name === "业务核心") {
            let params = {
              pageNum: 1,
              pageSize: 4,
              menuId: arr[i].id,
              type: 0
            };
            getContentList(params).then(res1 => {
              this.syywlist = res1.rows;
            });
          }
          if (arr[i].name === "战略合作") {
            let params = {
              pageNum: 1,
              pageSize: 12,
              type: 5
            };
            getContentList(params).then(res1 => {
              this.hezuolist = res1.rows;
            });
          }
        }
      });
    });
    let params = {
      pageNum: 1,
      pageSize: 6,
      menuId: 12 //新闻咨询
    };
    getContentList(params).then(res1 => {
      this.synewslist = res1.rows;
    });
  },
  methods: {
    //战略合作跳转
    jump(a) {
      window.open(a);
    },
    //更多资讯
    moremes() {
      this.newsPageNum++;
      let params = {
        pageNum: this.newsPageNum,
        pageSize: 6,
        type: 4
      };
      getContentList(params).then(res => {
        if (res.rows.length > 0) {
          this.synewslist = this.synewslist.concat(res.rows);
          if (res.rows.length < 6) {
            this.moreNewsLabel = "没有更多了~";
          }
        } else {
          this.moreNewsLabel = "没有更多了~";
        }
      });
    },
    init() {
      let params = {
        pageNum: 1,
        pageSize: 1,
        type: 1
      };
      getContentList(params).then(res => {
        this.topLabel=res.rows[0].content;
      });
    },
    // 业务中心
    yewu() {
      this.$router.push({
        path: "/business"
      });
    },
    //获取新闻详情
    news(key) {
      this.$router.push({
        path: "/news/detail",
        query: {
          newsId: key
        }
      });
    },
  },
  mounted() {
    this.init();
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-align: center;
}

.ywx_content_line {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ywx_content_line1 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.lbimage {
  height: 546px;
  min-width: 1200px;
  position: relative;
}

.lbimage .lbcontent {
  text-align: center;
  width: 1200px;
  position: absolute;
  left: 50%;
  top: 150px;
  transform: translate(-600px);
  z-index: 99;
}

.lbcontent .lbbutton {
  margin-top: 30px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.middlemenu {
  width: 100%;
  height: auto;
}

.middlemenu {
  margin-top: 30px;
  background: url("../../static/切图文件/nav/bg-map-1.png") no-repeat top center;
}

.ywmenu {
  margin: 0 auto;
}

.ywmenu > ul > li {
  display: inline-block;
  margin-right: 10px;
}

.ywcontent {
  margin: 0 auto;
  width: 1200px;
  margin-top: 10px;
  height: auto;
  /* text-align: center; */
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.ywcontent .ywx_content {
  text-align: center;
  width: 588px;
  height: 200px;
  display: inline-block;
  /* border: 1px solid #dddddd; */
  margin-top: 20px;
  background: url("../../static/切图文件/sbg.jpg") no-repeat;
  box-shadow: 0 0 5px 1px orange;
}

.ywcontent .ywx_content:hover {
  background: url("../../static/切图文件/cbg.png") no-repeat;
  box-shadow: 0 0 5px 2px #3366ff;
  /* background-position-x: -14px;
  background-position-y: -14px; */
  border: none;
  cursor: pointer;
}

.ywcontent .ywx_content .hthtml {
  margin-left: 0;
}

/* 奇数 */
.ywcontent .ywx_content:nth-of-type(old) {
  margin-right: 20px;
}

/* 偶数 */
.ywcontent .ywx_content:nth-of-type(even) {
  margin-left: 20px;
}

.news .newscontent {
  margin: 0 auto;
  width: 1200px;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  vertical-align: top;
}

.bttitle {
  /* margin-top: 30px; */
  font-size: 26px;
  font-weight: 900;
  color: rgb(249, 145, 0);
  display: inline-block;
}

.imageww {
  display: inline-block;
  vertical-align: text-bottom;
}

.news .newscontent #xiashang {
  display: inline-block;
  width: 358px;
  height: 234px;
  margin-bottom: 40px;
  border: 1px solid #dddddd;
}

.ywcontent .ywx_content p {
  margin-left: 0;
  margin-right: 0;
}

.news .newscontent #xiashang .mdimgcontent {
  width: 358px;
  height: 180px;
  position: relative;
}

.news .newscontent #xiashang .dw {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 99;
  text-align: left;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.morenews {
  width: 100%;
  /* height: 350px; */
  min-width: 1200px;
  background-color: #f7f7f7; /* rgb(81, 85, 88) */
  margin-top: 50px;

  position: relative;
}

.morenews .dwbtn {
  width: 120px;
  height: 40px;
  position: absolute;
  top: -20px;
  left: 50%;
  text-align: center;
  transform: translate(-50px);
}

.morenews .xtb {
  padding-top: 70px;
}

.morenews .xtb p {
  color: black;
  font-weight: 900;
}

.morenews .hezuo {
  margin: 20px auto 0 auto;
  width: 1200px;
  padding-bottom: 50px;
  /* height: 200px; */
}

.logo img {
  height: 60px;
  margin-bottom: 30px;
  width:100%;
}

.news .newscontent #xiashang:hover {
  box-shadow: 0 0 20px #aaa;
}
</style>